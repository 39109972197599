import React from "react"
import { MapContainer, TileLayer, Marker } from 'react-leaflet'

const Carte = ({ loc, zoom, height = "20rem" }) => {

    return (
        <>
            <MapContainer center={loc} style={{ height: height }} zoom={zoom} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={loc} />
            </MapContainer>
        </>
    )
}

export default Carte
