import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Carte from "../components/carte"

import { Container } from "react-bootstrap"
import "../../scss/pages/_infos-pratiques.scss"

const InfosPratiquesPage = () => (
    <Layout>
        <SEO title="Infos pratiques" />
        <Container>
            <h1 style={{ marginTop: "2rem" }}>Infos pratiques</h1>
            Voici quelques conseils pour faire votre sac de façon à profiter au maximum de l’excursion. N’hésitez pas à me contacter si vous avez des questions !<br />
            La montagne est un milieu changeant et extrême. Eté comme hiver, elle peut présenter un soleil brûlant sans ombre ou des chutes de neige. A partir d’une certaine altitude, le rayonnement solaire donne une impression de chaleur mais l’air reste frais à froid. Pour passer un bon moment, veillez à être paré à toute éventualité, sans toutefois vous surcharger : bouclé, votre sac ne doit pas excéder 8 kg pour une randonnée à la journée.<br />
            De bonnes chaussures, surtout pas neuves, avec la semelle bien crantée et des chaussettes montantes et pas trop épaisses.<br />
            Un sac à dos avec une ceinture abdominale, qui permet de répartir le poids entre les épaules et les hanches.<br /><br />
            Pluie :<br />
            -K-way<br />
            -Mettre les vêtements de rechange dans des sacs plastiques solides dans votre sac<br /><br />
            Froid :<br />
            -Pull<br />
            -Tee-shirt de rechange<br />
            -Echarpe et bonnet éventuellement<br /><br />
            Soleil, UV :<br />
            -Crème solaire<br />
            -Couvre-chef<br />
            -Lunettes de soleil<br />
            -Vêtements légers et couvrants<br /><br />
            Au moins 1,5L d’eau par personne<br />
            ...et le casse croûte bien sûr!<br /><br />
            Et s’il reste de la place : <br />
            -Jumelles<br />
            -Appareil photo

            <p style={{ margin: "2rem 0px 2rem" }}>
                Le Cabri Qui Flâne<br />
                Las Castagneres<br />
                65150 Tibiran-Jaunac
            </p>

            <Carte loc={[43.05081548632626, 0.5418223354543983]} zoom={5} />
        </Container>
    </Layout>
)

export default InfosPratiquesPage